import { useSearchContext } from "@/contexts/SearchContext";
import useCurrentUser from "@/hooks/useCurrentUser";
import { useEffect } from "react";
import aa from "search-insights";

const timePeriods = [5, 10, 20, 60]; // Time periods in seconds

const useSendConversionEventToAlgolia = (entry) => {
  const { lastAlgoliaQueryId } = useSearchContext();
  const { currentUser } = useCurrentUser();
  const userToken = currentUser?.uid;
  const sectionHandle = entry.sectionHandle;

  useEffect(() => {
    if (lastAlgoliaQueryId === null || !userToken) return;

    const objectIDs = [entry.id];
    const conversionTimers = [];

    timePeriods.forEach((seconds) => {
      const milliseconds = seconds * 1000;
      const timer = setTimeout(() => {
        const options = {
          eventType: "conversion",
          eventName: `Viewed ${sectionHandle} entry for >= ${seconds} Seconds`,
          index: process.env.NEXT_PUBLIC_ALGOLIA_ENTRY_INDEX_NAME,
          userToken,
          objectIDs,
          queryID: lastAlgoliaQueryId,
        };

        aa("convertedObjectIDsAfterSearch", options);

        if (process.env.NEXT_PUBLIC_VERCEL_ENV !== "production")
          console.log("Converted Object IDs After Search", options);
      }, milliseconds);

      conversionTimers.push(timer);
    });

    // Cleanup
    return () => {
      conversionTimers.forEach((timer) => clearTimeout(timer));
    };
  }, [lastAlgoliaQueryId, userToken, entry.id, sectionHandle]);
};

export default useSendConversionEventToAlgolia;
