import EntryCarousel from "@/components/EntryCarouselNew";
import clsx from "clsx";
import useSWR from "swr";

const fetcher = (url) => fetch(url).then((res) => res.json());

const SimilarEntries = ({ entryId, heading = "Why not try..." }) => {
  const { data, error } = useSWR(
    `/api/search/recommended?entryId=${entryId}`,
    fetcher
  );

  if (!data || !data.results || data.results.length === 0) return null;

  return (
    <div className={clsx("SimilarEntries", "print:hidden")}>
      <div className={clsx("space-y-4 lg:space-y-6 2xl:space-y-8")}>
        <div className="px-6 lg:px-12">
          <h2 className="heading-sm--spatial">{heading}</h2>
        </div>
        <div className={clsx("")}>
          <EntryCarousel entries={data.results} showSection showChef />
        </div>
      </div>
    </div>
  );
};

export default SimilarEntries;
